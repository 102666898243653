function loadGtagScript() {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer.push(arguments);
  };

  // todo: use env
  const token = 'G-7KKGJPRJZT'!;

  // @ts-ignore
  window.gtag('js', new Date());
  window.gtag('config', token);

  window.gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
    wait_for_update: 500,
  });

  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${token}`;
  script.id = 'loadGtagScriptID';
  script.async = true;

  document.body.append(script);
}

function loadYMScript() {
  const script = document.createElement('script');
  script.id = 'loadYMScriptID';
  script.type = 'text/javascript';
  script.innerHTML = `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
               m[i].l=1*new Date();
               for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
               k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
               (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
            
               ym(90017969, "init", {
                    clickmap:true,
                    trackLinks:true,
                    accurateTrackBounce:true,
                    webvisor:true
           });`;
  script.async = true;

  document.body.append(script);
}

function sendSafeEvent(type: 'ym' | 'gtag', callback: () => void) {
  if (typeof window === 'undefined' || process.env.NEXT_PUBLIC_RUNTIME_ENV === 'development') {
    return;
  }

  if (typeof window[type] !== 'undefined') {
    callback();
    return;
  }

  let attempt = 0;
  const timerId = setInterval(() => {
    attempt += 1;

    if (attempt > 2) {
      clearInterval(timerId);
      return;
    }

    if (typeof window[type] !== 'undefined') {
      callback();
      clearInterval(timerId);
    } else {
      console.log(`${type} мetric not initialized`);
    }
  }, 500);

  return timerId;
}

function sendSafeYMEvent(name: string, paramets?: object) {
  sendSafeEvent('ym', () => window.ym(90017969, 'reachGoal', name, paramets));
}
function sendSafeGtagEvent(name: string, parameters?: object) {
  sendSafeEvent('gtag', () => window.gtag('event', name, parameters));
}

export { loadYMScript, loadGtagScript, sendSafeYMEvent, sendSafeGtagEvent };
